import * as React from "react";
import {css, Theme} from "@emotion/react";

import {Text} from "@pg-design/text-module";

interface IProps {
    title: string;
    text: string;
}

export const ResultsInfo = (props: IProps) => {
    return (
        <div css={infoHolder}>
            <Text variant="body_copy_1">{props.title}</Text>

            <Text variant="info_txt_2">{props.text}</Text>
        </div>
    );
};

//Info
const infoHolder = (theme: Theme) => css`
    padding: 1rem 2rem;
    background: ${theme.colors.gray[200]};

    @media (min-width: ${theme.breakpoints.md}) {
        padding: 2rem 3rem;
        font-size: 1.6rem;
    }
`;
