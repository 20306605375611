import * as React from "react";
import {css, SerializedStyles, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {pb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    title: React.ReactNode;
    subTitle?: string;
    idx?: number;
    countLabel?: string | null;
    activeItemId?: number | null;
    scrollId?: string;
    href?: string;
    titleCss?: SerializedStyles | SerializedStyles[];
    titleAs?: "div";
}

export const ResultsListItem = (props: IProps) => {
    const isItemActive = () => {
        if (props.idx !== undefined && props.activeItemId !== undefined) {
            return props.idx === props.activeItemId && true;
        }
        return false;
    };

    const elementType = props.href ? "a" : "div";
    return (
        <li>
            <ListItem
                data-testid="results-list-item"
                id={`scrollableItem-${props.idx}`}
                onClick={(e) => props.onClick(e)}
                isActive={isItemActive()}
                as={elementType}
                {...(elementType === "a" && {href: props.href})}
            >
                <TitlesHolder subtitleVisible={!!props.subTitle}>
                    <Text variant="body_copy_2" css={[labelStyle, props.titleCss]} as={props.titleAs}>
                        {props.title}
                    </Text>
                    {props.subTitle && (
                        <Text variant="info_txt_1" css={subLabelColor}>
                            {props.subTitle}
                        </Text>
                    )}
                </TitlesHolder>

                {props.countLabel && (
                    <Text variant="info_txt_1" css={propertyCountHolder}>
                        {props.countLabel}
                    </Text>
                )}
            </ListItem>
        </li>
    );
};

interface IListItemProps {
    isActive: boolean;
}

const ListItem = styled.div<IListItemProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.4rem;
    padding: 1rem;
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;
    ${(props) =>
        props.isActive &&
        css`
            background: #f2f2f2;
        `}

    &:hover {
        background: #f2f2f2;
    }
`;

interface ITitlesHolder {
    subtitleVisible: boolean;
}

const TitlesHolder = styled.div<ITitlesHolder>`
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.theme.globals.text.root};

    span:first-of-type {
        text-transform: capitalize;
        font-size: ${(props) => props.theme.globals.text.root};
    }

    span:last-of-type {
        // color: ${(props) => (props.subtitleVisible ? props.theme.colors.gray : "")};
    }
`;

const propertyCountHolder = (theme: Theme) => css`
    font-size: ${theme.globals.text.root};
    color: ${theme.colors.gray[700]};
    white-space: nowrap;
`;

const labelStyle = (theme: Theme) => css`
    color: ${theme.colors.secondary};
    ${pb(1)}
`;
const subLabelColor = (theme: Theme) => css`
    color: ${theme.colors.gray[700]};
`;
