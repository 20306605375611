import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, mb, mt, p} from "@pg-design/helpers-css";
import {GalleryOutlineIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

interface IProps {
    className?: string;
    photosAmount: number;
}

const galleryImageCountPlural = pluralize(["zdjęcie", "zdjęcia", "zdjęć"]);

export function GalleryCount(props: IProps) {
    const {className, photosAmount} = props;
    const theme = useTheme();
    return (
        <div css={counterHolder} className={className}>
            <div css={counterHolderIcon}>
                <GalleryOutlineIcon size="2.8" fill={theme.colors.secondary} />
            </div>

            <Text variant="info_txt_3" align="center" noWrap>
                {photosAmount} {galleryImageCountPlural(photosAmount)}
            </Text>
        </div>
    );
}

// Styles
const counterHolder = (theme: Theme) => css`
    ${borderRadius(0.5)}
    ${p(0.5)}
    background-color: ${theme.colors.success};
    pointer-events: none;
`;

const counterHolderIcon = css`
    ${mb(1)}
    ${mt(1)}
    display: flex;
    justify-content: center;
    align-items: center;
`;
