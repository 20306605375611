import React from "react";

import {CenterBox} from "@pg-design/grid-module";
import {Loader} from "@pg-design/loader";
import {RequestState} from "@pg-mono/request-state";
import {pluralize} from "@pg-mono/string-utils";

import {SearchTab} from "../../../actions/fetch_search_all_action";
import {IVendorListVendor} from "../../../actions/fetch_search_vendors_action";
import {IActiveDropdownItemStore} from "../../../reducers/active_dropdown_item_reducer";
import {resultsList} from "../../atoms/atoms";
import {ResultsInfo} from "../../atoms/ResultsInfo";
import {ResultsListItem} from "../../atoms/ResultsListItem";
import {ISearchInputValue} from "../../ISearchInputValue";

interface IOwnProps {
    onLinkClick: (option: ISearchInputValue) => void;
    fetchVendorsRequest: RequestState;
    fetchPlacesRequest: RequestState;
    activeItem: IActiveDropdownItemStore;
    vendors: IVendorListVendor[];
}

export const VendorsTab = (props: IOwnProps) => {
    const {vendors, fetchVendorsRequest, fetchPlacesRequest} = props;
    // empty message prevented until next tabs finish request
    if (fetchVendorsRequest === RequestState.Success && fetchPlacesRequest !== RequestState.Waiting && vendors.length === 0) {
        return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
    }
    // display list/null also on fetching because we do not want loader to interrupt list view
    if (fetchVendorsRequest === RequestState.Success || fetchVendorsRequest === RequestState.Waiting) {
        if (vendors.length === 0) {
            return (
                <CenterBox>
                    <Loader />
                </CenterBox>
            );
        }

        return (
            <ul css={resultsList}>
                {props.vendors.map((vendor, idx) => {
                    const offersForSale = vendor.stats && vendor.stats.offers_count_for_sale;
                    const offersForSaleFormat = offersForSale && pluralize(["inwestycja", "inwestycje", "inwestycji"])(offersForSale);
                    const onLinkClick = () =>
                        props.onLinkClick({
                            label: vendor.name,
                            tabType: SearchTab.Vendors,
                            vendor: vendor
                        });

                    return (
                        <ResultsListItem
                            key={idx}
                            idx={idx}
                            activeItemId={props.activeItem.id}
                            title={vendor.name}
                            countLabel={`${offersForSale} ${offersForSaleFormat}`}
                            onClick={onLinkClick}
                        />
                    );
                })}
            </ul>
        );
    }
    return null;
};
