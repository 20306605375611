import React from "react";
import {css, useTheme} from "@emotion/react";

import {pr} from "@pg-design/helpers-css";
import {MapMarkerOutlineIcon} from "@pg-design/icons-module";
import {Pill} from "@pg-design/pill";
import {useIsMobile} from "@pg-mono/hooks";

import {SearchTab} from "../actions/fetch_search_all_action";
import {ISearchInputValue} from "./ISearchInputValue";

interface IProps {
    search: ISearchInputValue;
    selectedTab: SearchTab;
    removeRegionTag: (regionId: number | null) => void;
}

export const RegionTabs = (props: IProps) => {
    const isMobile = useIsMobile();
    const theme = useTheme();
    const preventDesktopDefault = (e: React.MouseEvent<HTMLDivElement>) => {
        // keeps focus on search field on desktop, caused weird scroll behaviour on mobile when clicked inside search modal.
        if (!isMobile) {
            e.preventDefault();
        }
    };

    if (props.search.tabType === SearchTab.Regions && props.search.regions.length > 0) {
        const regions = props.search.regions.slice(0, 3);

        return (
            <div css={regionsHolderStyle} onMouseDown={preventDesktopDefault}>
                {regions.map((region) => (
                    <Pill
                        key={region.id}
                        size="large"
                        css={regionBadgeStyle}
                        onCloseClick={(e) => {
                            preventDesktopDefault(e);
                            props.selectedTab === SearchTab.Regions && props.removeRegionTag(region.id);
                        }}
                    >
                        <MapMarkerOutlineIcon fill={theme.colors.secondary} size="2.4" css={pr(1)} wrapperColor="transparent" wrapperSize="2.4" />

                        <div css={[titleHolder, truncate]}>{region.full_name_reverted}</div>
                    </Pill>
                ))}
            </div>
        );
    }
    return null;
};

//Region tabs
const regionsHolderStyle = () => css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    div:last-of-type {
        margin-right: 0;
    }
`;

// TODO: use some common `truncate` ie. in Text component
const truncate = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const regionBadgeStyle = css`
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const titleHolder = css`
    flex-grow: 1;
    padding-right: 1rem;
`;
